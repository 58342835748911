<template>
  <div>
    <div class="rb-mask rb-mask-show" @click="close"></div>
    <div class="rb-back-dialog animation-dialog dialog-visible">
      <img v-lazy class="back__logo" src="../assets/GooglePlaybestapp.png" alt="" />
      <div class="back__title">We are collecting <span>objective</span> reviews for this apps</div>
      <div class="back__block">
        <img v-lazy class="back__icon" src="../../public/icon.png" alt="" />
        <div class="back__info">
          <div class="back__info-title">
            AyalaWin
            <img v-lazy alt="" src="../assets/icon-tag.png" />
          </div>
          <p class="back__info-description">AyalaWin.com</p>
          <p class="back__info-play">
            <img v-lazy alt="" class="ic_dun" src="../assets/ic_dun.png" />
            Verified by Play Protect
          </p>
        </div>
      </div>
      <div class="back__detail">
        <img v-lazy src="../assets/GooglePlaybestapp2.png" alt="" />
        <div class="back__detail-introduction">
          <p>
            If you can <span>download the app</span> and leave an <span>objective</span> review and rating，You will
            have the chance to receive a <span>$20</span> Google Play gift card.
          </p>
        </div>
      </div>
      <div class="back__btn" @click="Install">Install</div>
    </div>
  </div>
</template>

<script setup>
import { defineEmits, ref } from 'vue';
let isShow = ref(false);

const emit = defineEmits(['childEvent', 'closePop']);
function close() {
  emit('closePop');
}
function Install() {
  emit('childEvent');
}

// watch(isBoxShow.value, (newValue) => {
//   emit('update:parentValue', newValue);
// });
</script>

<style scoped>
div {
  box-sizing: border-box;
}

.rb-mask-show {
  animation-name: rb-fade-in;
}

.rb-mask {
  animation-duration: 0.35s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
  background: rgba(0, 0, 0, 0.6);
  height: 100%;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  visibility: hidden;
  width: 100%;
  z-index: 1000;
}

.animation-dialog.dialog-visible {
  animation-name: rb-fade-in;
}

.rb-back-dialog {
  align-items: center;
  background-color: #fff;
  border-radius: 18px;
  display: flex;
  flex-direction: column;
  left: 0;
  margin: 0 auto;
  opacity: 0;
  padding: 15px 10px;
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
  width: 85%;
  z-index: 1000;
}

.animation-dialog {
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
  opacity: 0;
  visibility: hidden;
}

.rb-back-dialog .back__logo {
  width: 166px;
}

.rb-back-dialog .back__block {
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.23);
  display: flex;
  margin: 15px 0;
  padding: 20px;
  width: 100%;
}

.rb-back-dialog .back__title {
  font-size: 17px;
  line-height: 24px;
  margin-top: 32px;
  text-align: center;
}

.rb-back-dialog .back__detail {
  align-items: center;
  display: flex;
  font-size: 14px;
}

.rb-back-dialog .back__btn {
  align-items: center;
  background-color: #028760;
  border-radius: 8px;
  color: #fff;
  display: flex;
  font-size: 14px;
  height: 35px;
  justify-content: center;
  margin-top: 15px;
  width: 100%;
}

.rb-back-dialog .back__icon {
  border-radius: 20%;
  height: 70px;
  margin-right: 20px;
  width: 70px;
}

.rb-back-dialog .back__info-title {
  align-items: center;
  display: flex;
  font-size: 22px;
  font-weight: 500;
  overflow-wrap: anywhere;
  word-break: keep-all;
}

.rb-back-dialog .back__info-description {
  color: #028760;
  font-size: 15px;
  font-weight: 500;
  margin: 5px 0;
  text-align: left;
}

.rb-back-dialog .back__info-play {
  align-items: center;
  color: #333;
  display: flex;
  font-size: 10px;
  font-weight: 500;
  height: 14px;
}

.rb-back-dialog .back__detail img {
  width: 89px;
}

.rb-back-dialog .back__info-title img {
  height: 20px;
  margin-left: 5px;
  width: 20px;
}

.rb-back-dialog .back__info-play .ic_dun {
  margin-right: 4px;
  width: 10px;
}

.dialogHidden {
  animation-duration: 0.15s;
  animation-name: rb-fade-out;
}

@keyframes rb-fade-in {
  0% {
    opacity: 0;
    visibility: hidden;
  }

  to {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes rb-fade-out {
  0% {
    opacity: 1;
    visibility: visible;
  }

  to {
    opacity: 0;
    visibility: hidden;
  }
}
</style>
