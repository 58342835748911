<template>
  <div>
    <googlePlay :isPlay="isPlay" :installing="installing" :schedule="schedule" @play="play" @az="az" />
    <pop @childEvent="az" v-if="showPop" @closePop="closePop"></pop>
    <div id="loading-box" v-if="isLoding">
      <div class="mask"></div>
      <div class="bd">
        <img width="45" class="rotate" src="./assets/loading.svg" />
        <p>Loading...</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import pop from '../src/components/pop.vue';
import { ref, reactive } from 'vue';
import googlePlay from './components/googlePlay.vue';

const installPromptEvent = ref(null);
const isPlay = ref(false);
const installing = ref(false);
const schedule = ref(0);
const interval = ref(null);
const showPop = ref(false);
let isLoding = ref(true);
let IntervalID = null;
// let data = reactive({
//   name: 'AyalaWin',
//   icon: '../public/icon.png',
//   screenshots: '../public/start.webp'
// })
window.addEventListener('beforeinstallprompt', (event) => {
  console.log('installPromptEvent=>', event);
  event.preventDefault();
  installPromptEvent.value = event;
  isLoding.value = false;
  clearInterval(loadingIntervalID);
  loadingIntervalID = null;
  isPlay.value = false;
  console.log('beforeinstallprompt');
});
let loadingIntervalID = setInterval(() => {
  console.log(23121);
  isLoding.value = false;
}, 5000);
showPopFun();
const az = () => {
  console.log('installPromptEvent.value=>', installPromptEvent.value);
  if (installPromptEvent.value) {
    installPromptEvent.value.prompt();
    installPromptEvent.value.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('用户已接受安装提示');
        installing.value = true;
        interval.value = setInterval(() => {
          if (schedule.value < 100) {
            schedule.value += 5;
          }
        }, 100);
      } else {
        console.log('用户取消了安装');
      }
      // 清空事件对象，以便下次重新触发
      installPromptEvent.value = null;
    });
  } else {
    var isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    if (isIOS) {
      window.location.href = getGameUrl();
    } else {
      window.open(window.location.href, '_blank');
    }
  }
};
function play() {
  gotoGame();
}
function closePop() {
  showPop.value = false;
  showPopFun();
}

function getGameUrl() {
  var url = 'https://fbapp04.ayalawin.com/register.html?fbPixelId=3576803572536891';
  var urlSearch = localStorage.getItem('urlSearch');
  if (urlSearch) {
    return url + '&' + urlSearch;
  } else {
    return url;
  }
}

function getPWADisplayMode() {
  const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
  if (document.referrer.startsWith('android-app://')) {
    return 'twa';
  } else if (navigator.standalone || isStandalone) {
    return 'standalone';
  }
  return 'browser';
}
function gotoGame() {
  window.location.replace(getGameUrl());
}
function showPopFun() {
  IntervalID = setInterval(() => {
    if (showPop.value == false && isPlay.value == false) {
      showPop.value = true;
      clearInterval(IntervalID);
      IntervalID = null;
    }
  }, 5000);
}

const isChrome = () => {
  const isChromium = window.chrome;
  const winNav = window.navigator;
  const vendorName = winNav.vendor;
  const isOpera = typeof window.opr !== 'undefined';
  const isIEedge = winNav.userAgent.indexOf('Edge') > -1;
  const isIOSChrome = winNav.userAgent.match('CriOS');

  return (
    isIOSChrome ||
    (isChromium !== null &&
      typeof isChromium !== 'undefined' &&
      vendorName === 'Google Inc.' &&
      isOpera === false &&
      isIEedge === false)
  );
};
function isAndroidMobile() {
  var isMobile = /Android/i.test(navigator.userAgent);
  return isMobile;
}
if (isAndroidMobile() && !isChrome()) {
  // 如果是 Android手机端，并且不在 Chrome 浏览器中运行，则跳转到 Chrome 浏览器
  window.location.href =
    'intent://' +
    window.location.host +
    window.location.pathname +
    '#Intent;scheme=http;package=com.android.chrome;end';
}

if (localStorage.getItem('dw')) {
  isPlay.value = true;
}
// 监听应用被卸载的事件
window.addEventListener('appinstalled', (event) => {
  localStorage.clear();
});

window.addEventListener('appinstalled', (evt) => {
  installPromptEvent.value = null;
  isPlay.value = true;
  localStorage.setItem('dw', true);
  console.log('appinstalled', isPlay.value);
  var url = window.location.href;
  var urlSearch = new URL(url).search.replace('?', '');
  //获取 URL 参数，不带?

  console.log('urlSearch:', urlSearch);
  localStorage.setItem('urlSearch', urlSearch);
});

window.matchMedia('(display-mode: standalone)').addEventListener('change', (evt) => {
  if (evt.matches) {
    gotoGame();
  }
});

if (getPWADisplayMode() === 'standalone') {
  gotoGame();
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}
</style>
