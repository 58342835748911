<template>
  <div id="all" class="maxbox" @click="maxClick">
    <!-- <div v-if="!isPlay">     -->
    <div class="template-head">
      <div class="box">
        <div class="l">
          <a>
            <svg class="kOqhQd" aria-hidden="true" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
              <path fill="none" d="M0,0h40v40H0V0z"></path>
              <g>
                <path
                  d="M19.7,19.2L4.3,35.3c0,0,0,0,0,0c0.5,1.7,2.1,3,4,3c0.8,0,1.5-0.2,2.1-0.6l0,0l17.4-9.9L19.7,19.2z"
                  fill="#EA4335"></path>
                <path
                  d="M35.3,16.4L35.3,16.4l-7.5-4.3l-8.4,7.4l8.5,8.3l7.5-4.2c1.3-0.7,2.2-2.1,2.2-3.6C37.5,18.5,36.6,17.1,35.3,16.4z"
                  fill="#FBBC04"></path>
                <path
                  d="M4.3,4.7C4.2,5,4.2,5.4,4.2,5.8v28.5c0,0.4,0,0.7,0.1,1.1l16-15.7L4.3,4.7z"
                  fill="#4285F4"></path>
                <path
                  d="M19.8,20l8-7.9L10.5,2.3C9.9,1.9,9.1,1.7,8.3,1.7c-1.9,0-3.6,1.3-4,3c0,0,0,0,0,0L19.8,20z"
                  fill="#34A853"></path>
              </g>
            </svg>
            <h1>Google Play</h1>
          </a>
        </div>
        <div class="r">
          <a>
            <img width="24" src="../assets/search.svg" alt="" />
          </a>
          <a> <img width="24" src="../assets/help.svg" alt="" /></a>
          <a> <img src="../assets/avatar.png" alt="" /></a>
        </div>
      </div>
    </div>
    <div class="template-bd">
      <!-- <div id="loading-box" style="display: none;">
	  <div class="mask"></div>
	  <div class="bd">
	    <img width="45" class="rotate" src="../assets/loading.svg">
	    <p>Loading...</p>
	  </div>
	</div> -->
      <!-- <div id="pop-show">
        <div class="mask"></div>
        <div class="bd">
          <strong> <img src="../assets/lightning.svg">Install</strong>

          <div class="loading-ci">
            <div class="box"></div>
            <span id="count1">{{props.schedule}}</span>%
          </div>
          <div class="show-btn">
            <div class="active-box">
              <span><img src="../assets/safe.svg">Effective</span>
            </div>
            <button class="btn big" id="click-btn" @ckick="az">Install Now</button>
          </div>
        </div>
      </div> -->
      <div class="main-up">
        <div class="logo">
          <div class="box"></div>
          <img src="../../public/icon.png" alt="AyalaWin" />
        </div>
        <div class="info">
          <h1>AyalaWin</h1>
          <h2>AyalaWin most popular in Philippine</h2>
          <p>Verified by App</p>
        </div>
      </div>
      <ul class="information-list">
        <li>
          <strong>
            4.9
            <img src="../assets/start.png" alt="" />
          </strong>
          <p>2K reviews</p>
        </li>
        <li>
          <strong>123K +</strong>
          <p>Downloads</p>
        </li>
        <li>
          <strong><img class="large" src="../assets/111.svg" /></strong>
          <p>Everyone</p>
        </li>
      </ul>

      <div class="btn-box shiny">
        <!-- <button id="loading" class="btn">Loading...</button> -->

        <button class="btn" @click.stop="az" v-if="!props.installing && !props.isPlay">Install</button>

        <div id="installing" class="btn" v-if="props.installing && !props.isPlay">
          <div id="proess" :style="{ width: props.schedule + '%' }"></div>
          <span id="count">{{ props.schedule }}</span
          ><span>%</span>
        </div>
        <button id="play" @click.stop="play" class="btn" v-if="props.isPlay">Play</button>
      </div>

      <ul class="google-share-btns">
        <li>
          <svg class="f70z8e" width="24" height="24" viewBox="0 0 24 24">
            <path
              d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92 1.61 0 2.92-1.31 2.92-2.92s-1.31-2.92-2.92-2.92z"></path>
          </svg>
          <span>Share</span>
        </li>
        <li>
          <svg class="XkAcee" width="24" height="24" viewBox="0 0 24 24">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7 3H17C18.1045 3 19 3.8955 19 5V21L12 18L5 21L5.01075 5C5.01075 3.8955 5.8965 3 7 3ZM12 15.824L17 18V5H7V18L12 15.824ZM13 7V9H15V11H13V13H11V11H9V9H11V7H13Z"></path>
          </svg>
          <span>Add to wishlist</span>
        </li>
      </ul>
      <div class="banner-imgs-box">
        <ul class="banner-imgs">
          <li><img src="../assets/unnamed1.webp" alt="" /></li>
          <li><img src="../assets/unnamed2.webp" alt="" /></li>
          <li><img src="../assets/unnamed3.webp" alt="" /></li>
          <li><img src="../assets/unnamed4.webp" alt="" /></li>
        </ul>
      </div>

      <div class="introduce">
        <h2>About this app</h2>
        <div>
          AyalaWin Games have most popular game in Philippine Let's play traditional and modern games: - Shan Koe Mee -
          Boogyi -Poker - and many Slots games
        </div>
      </div>
      <div class="update-box">
        <h3>Updated on</h3>
        <p>Mar 05, 2024</p>
      </div>
      <div class="introduce data-safety-box">
        <h2>Data safety</h2>
        <p>
          Safety starts with understanding how developers collect and share your data. Data privacy and security
          practices may vary based on your use,region, and age. The developer provided this information and may update
          it over time.
        </p>
      </div>
      <div class="data-safety-list">
        <ul>
          <li>
            <img src="../assets/google/1.png" alt="" />
            <div>
              No data shared with third parties
              <p><span>Learn more</span> about how developers declare sharing</p>
            </div>
          </li>
          <li>
            <img src="../assets/google/2.png" alt="" />
            <div>
              This app may collect these data types
              <p>Location, Personal info and 9 others</p>
            </div>
          </li>
          <li>
            <img src="../assets/google/3.png" alt="" />
            <div>Data is encrypted in transit</div>
          </li>
          <li>
            <img src="../assets/google/4.png" alt="" />
            <div>You can request that data be deleted</div>
          </li>
        </ul>
        <p>See details</p>
      </div>
    </div>
    <div class="template-footer">
      <ul>
        <li>
          <a>
            <p><img width="24" src="../assets/game.svg" alt="" /></p>
            <span>Games</span>
          </a>
        </li>
        <li class="active">
          <a>
            <p><img width="24" src="../assets/app.svg" alt="" /></p>
            <span>Apps</span>
          </a>
        </li>
        <li>
          <a>
            <p><img width="24" src="../assets/file.svg" alt="" /></p>
            <span>Movies</span>
          </a>
        </li>
        <li>
          <a>
            <p><img width="24" src="../assets/book.svg" alt="" /></p>
            <span>Books</span>
          </a>
        </li>
        <li>
          <a>
            <p><img width="24" src="../assets/child.svg" alt="" /></p>

            <span>Kids</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import './index.css';
const props = defineProps({
  isPlay: {
    type: Boolean,
  },
  installing: {
    type: Boolean,
  },
  schedule: {
    type: Number,
  },
});
const emit = defineEmits(['play', 'az']);
const play = () => {
  emit('play');
};
const az = () => {
  emit('az');
};

const maxClick = () => {
  if (props.isPlay) {
    play();
  } else {
    az();
  }
};
</script>

<style scoped>
.maxbox {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #5f6368;
  position: relative;
}

.game {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
}
</style>
